import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>
      Je sais pas comment t'es arrivé là mais t'es pas sur la bonne route ...
    </p>
  </>
)

export default NotFoundPage
